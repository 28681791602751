@font-face {
  font-family: 'indicons';
  src: url("fonts/styleguide/indicons.eot");
  src: url("fonts/styleguide/indicons.eot#iefix") format("embedded-opentype"), url("fonts/styleguide/indicons.ttf") format("truetype"), url("fonts/styleguide/indicons.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'indicons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* Icons Glyphs */
.icon-account:before {
  content: "\e900"; }

.icon-achievement:before {
  content: "\e935"; }

.icon-api-doc:before {
  content: "\e92e"; }

.icon-arrow-dots:before {
  content: "\e92f"; }

.icon-arrow-up-circle:before {
  content: "\e91d"; }

.icon-arrows-cursor:before {
  content: "\e93c"; }

.icon-check:before {
  content: "\e901"; }

.icon-check-circle:before {
  content: "\e91e"; }

.icon-clipboard:before {
  content: "\e93d"; }

.icon-coffee:before {
  content: "\e902"; }

.icon-coffee-fail:before {
  content: "\e91a"; }

.icon-confidence-bar-1:before {
  content: "\e913"; }

.icon-confidence-bar-2:before {
  content: "\e914"; }

.icon-confidence-bar-3:before {
  content: "\e915"; }

.icon-confidence-bar-4:before {
  content: "\e916"; }

.icon-configure:before {
  content: "\e93e"; }

.icon-crowdlabel:before {
  content: "\e90f"; }

.icon-csharp:before {
  content: "\e94d"; }

.icon-dashboard:before {
  content: "\e92d"; }

.icon-data-and-access:before {
  content: "\e919"; }

.icon-database:before {
  content: "\e937"; }

.icon-droplet:before {
  content: "\e906"; }

.icon-edit:before {
  content: "\e91c"; }

.icon-education:before {
  content: "\e907"; }

.icon-ellipsis:before {
  content: "\e94c"; }

.icon-elmos-fire:before {
  content: "\e905"; }

.icon-email:before {
  content: "\e928"; }

.icon-enterprise:before {
  content: "\e908"; }

.icon-extraction:before {
  content: "\e93f"; }

.icon-file:before {
  content: "\e940"; }

.icon-filter:before {
  content: "\e941"; }

.icon-flag:before {
  content: "\e936"; }

.icon-gear:before {
  content: "\e923"; }

.icon-happy:before {
  content: "\e920"; }

.icon-help:before {
  content: "\e909"; }

.icon-help-solid:before {
  content: "\e92a"; }

.icon-highlight:before {
  content: "\e942"; }

.icon-indico-o:before {
  content: "\e917"; }

.icon-java:before {
  content: "\e94e"; }

.icon-key:before {
  content: "\e927"; }

.icon-keyboard:before {
  content: "\e950"; }

.icon-label:before {
  content: "\e92b"; }

.icon-layout-complex:before {
  content: "\e953"; }

.icon-layout-simple:before {
  content: "\e954"; }

.icon-lock:before {
  content: "\e926"; }

.icon-menu:before {
  content: "\e90a"; }

.icon-moon:before {
  content: "\e90b"; }

.icon-moonbow:before {
  content: "\e904"; }

.icon-chevron-down:before {
  content: "\e943"; }

.icon-chevron-left:before {
  content: "\e944"; }

.icon-chevron-right:before {
  content: "\e945"; }

.icon-chevron-up:before {
  content: "\e946"; }

.icon-organizations:before {
  content: "\e924"; }

.icon-output:before {
  content: "\e952"; }

.icon-page-thumbnail:before {
  content: "\e947"; }

.icon-pay-as-you-go:before {
  content: "\e90c"; }

.icon-plus:before {
  content: "\e90d"; }

.icon-popup:before {
  content: "\e91f"; }

.icon-python:before {
  content: "\e94f"; }

.icon-rabbit:before {
  content: "\e934"; }

.icon-recruiter:before {
  content: "\e930"; }

.icon-reject:before {
  content: "\e948"; }

.icon-repair:before {
  content: "\e951"; }

.icon-research:before {
  content: "\e90e"; }

.icon-retrain:before {
  content: "\e918"; }

.icon-sad:before {
  content: "\e921"; }

.icon-search:before {
  content: "\e92c"; }

.icon-split:before {
  content: "\e94b"; }

.icon-sun:before {
  content: "\e910"; }

.icon-surround:before {
  content: "\e949"; }

.icon-thumbs-down:before {
  content: "\e931"; }

.icon-thumbs-up:before {
  content: "\e932"; }

.icon-trash:before {
  content: "\e91b"; }

.icon-quality:before {
  content: "\e933"; }

.icon-unlock:before {
  content: "\e925"; }

.icon-user:before {
  content: "\e929"; }

.icon-wand:before {
  content: "\e911"; }

.icon-warning:before {
  content: "\e922"; }

.icon-wizard-hat:before {
  content: "\e94a"; }

.icon-workflows:before {
  content: "\e903"; }

.icon-x-close:before {
  content: "\e912"; }
