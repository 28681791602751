@font-face {
  font-family: indicons;
  src: url("indicons.e841e157.eot");
  src: url("indicons.e841e157.eot#iefix") format("embedded-opentype"), url("indicons.d81c942a.ttf") format("truetype"), url("indicons.44733e5e.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: indicons !important;
}

.icon-account:before {
  content: "";
}

.icon-achievement:before {
  content: "";
}

.icon-api-doc:before {
  content: "";
}

.icon-arrow-dots:before {
  content: "";
}

.icon-arrow-up-circle:before {
  content: "";
}

.icon-arrows-cursor:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-check-circle:before {
  content: "";
}

.icon-clipboard:before {
  content: "";
}

.icon-coffee:before {
  content: "";
}

.icon-coffee-fail:before {
  content: "";
}

.icon-confidence-bar-1:before {
  content: "";
}

.icon-confidence-bar-2:before {
  content: "";
}

.icon-confidence-bar-3:before {
  content: "";
}

.icon-confidence-bar-4:before {
  content: "";
}

.icon-configure:before {
  content: "";
}

.icon-crowdlabel:before {
  content: "";
}

.icon-csharp:before {
  content: "";
}

.icon-dashboard:before {
  content: "";
}

.icon-data-and-access:before {
  content: "";
}

.icon-database:before {
  content: "";
}

.icon-droplet:before {
  content: "";
}

.icon-edit:before {
  content: "";
}

.icon-education:before {
  content: "";
}

.icon-ellipsis:before {
  content: "";
}

.icon-elmos-fire:before {
  content: "";
}

.icon-email:before {
  content: "";
}

.icon-enterprise:before {
  content: "";
}

.icon-extraction:before {
  content: "";
}

.icon-file:before {
  content: "";
}

.icon-filter:before {
  content: "";
}

.icon-flag:before {
  content: "";
}

.icon-gear:before {
  content: "";
}

.icon-happy:before {
  content: "";
}

.icon-help:before {
  content: "";
}

.icon-help-solid:before {
  content: "";
}

.icon-highlight:before {
  content: "";
}

.icon-indico-o:before {
  content: "";
}

.icon-java:before {
  content: "";
}

.icon-key:before {
  content: "";
}

.icon-keyboard:before {
  content: "";
}

.icon-label:before {
  content: "";
}

.icon-layout-complex:before {
  content: "";
}

.icon-layout-simple:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

.icon-menu:before {
  content: "";
}

.icon-moon:before {
  content: "";
}

.icon-moonbow:before {
  content: "";
}

.icon-chevron-down:before {
  content: "";
}

.icon-chevron-left:before {
  content: "";
}

.icon-chevron-right:before {
  content: "";
}

.icon-chevron-up:before {
  content: "";
}

.icon-organizations:before {
  content: "";
}

.icon-output:before {
  content: "";
}

.icon-page-thumbnail:before {
  content: "";
}

.icon-pay-as-you-go:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-popup:before {
  content: "";
}

.icon-python:before {
  content: "";
}

.icon-rabbit:before {
  content: "";
}

.icon-recruiter:before {
  content: "";
}

.icon-reject:before {
  content: "";
}

.icon-repair:before {
  content: "";
}

.icon-research:before {
  content: "";
}

.icon-retrain:before {
  content: "";
}

.icon-sad:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-split:before {
  content: "";
}

.icon-sun:before {
  content: "";
}

.icon-surround:before {
  content: "";
}

.icon-thumbs-down:before {
  content: "";
}

.icon-thumbs-up:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-quality:before {
  content: "";
}

.icon-unlock:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-wand:before {
  content: "";
}

.icon-warning:before {
  content: "";
}

.icon-wizard-hat:before {
  content: "";
}

.icon-workflows:before {
  content: "";
}

.icon-x-close:before {
  content: "";
}
/*# sourceMappingURL=index.81128eca.css.map */
